import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.string.replace.js";

/**
 * 乘法精度丢失
 *  
 */
export function formatMultip(arg1, arg2) {
  var m = 0;
  var s1 = arg1.toString();
  var s2 = arg2.toString();

  try {
    m += s1.split('.')[1].length;
  } catch (e) {}

  try {
    m += s2.split('.')[1].length;
  } catch (e) {}

  return Number(s1.replace('.', '')) * Number(s2.replace('.', '')) / Math.pow(10, m);
}
/**
 *除法精度丢失
 * 
 */

export function formatDivision(arg1, arg2) {
  var t1 = 0;
  var t2 = 0;
  var r1, r2;

  try {
    t1 = arg1.toString().split('.')[1].length;
  } catch (e) {}

  try {
    t2 = arg2.toString().split('.')[1].length;
  } catch (e) {}

  r1 = Number(arg1.toString().replace('.', ''));
  r2 = Number(arg2.toString().replace('.', ''));
  var intDiv = r1 / r2;
  var pow = Math.pow(10, t2 - t1);
  return formatMultip(intDiv, pow); // 这里用上面定义好的乘法运算
}